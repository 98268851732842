.calendar-container {
    text-align: center;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
  }
  
  .calendar-day {
    border: 1px solid black;
    border-radius: 20px;
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
  }
  
  .today {
    background-color: #fc2b2b52;
  }
  
  .selected {
    background-color: #007bff;
    color: #fff;
  }
  
  .empty {
    visibility: hidden;
  }