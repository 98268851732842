.wrapper
{
    border:1px solid black;
    background-color: rgba(179, 118, 118, 0.637);
    
    justify-content: center;
}
h1, h2, h3, h4, h5
{
    color: black;
}